<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="录单时间">
              <a-range-picker @change="tableOnDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单类型">
              <a-select v-model="queryParam.type" style="width: 100%" @change="$refs.table.refresh(true)">
                <a-select-option :key="0">接单</a-select-option>
                <a-select-option :key="1">清关</a-select-option>
                <a-select-option :key="2">出库</a-select-option>
                <a-select-option :key="3">全部</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="业务类型">
              <a-select
                showSearch
                mode="multiple"
                allowClear
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="queryParam.business_type"
              >
                <a-select-option v-for="op in bizTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.serial_num" placeholder="根据订单编号搜索" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用">
              <a-select
                placeholder="请选择费用"
                v-model="queryParam.fee_id"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in feeOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                placeholder="请选择费用状态"
                v-model="queryParam.fee_status_list"
                showSearch
                allowClear
                style="width: 100%"
                mode="multiple"
              >
                <a-select-option v-for="o in this.GLOBAL.feeStatusMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票时间">
              <a-range-picker @change="onInvoiceDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客服员">
              <a-select
                showSearch
                allowClear
                placeholder="请选择客服员"
                style="width: 100%"
                v-model="queryParam.customer_id">
                <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" @click="handleExport('export_profit_data')">导出</a-button>
      <!--      <a-popover v-model="dateVisible" title="选择日期" trigger="click">-->
      <!--        <a slot="content">-->
      <!--          <p><a-range-picker @change="onDateChange" style="width: 200px" /></p>-->
      <!--          <p style="text-align: right"><a-button type="primary" @click="handleDateTimeStaticsExport">确定</a-button></p>-->
      <!--        </a>-->
      <!--        <a-button type="primary">导出周报统计表</a-button>-->
      <!--      </a-popover>-->
      <!--      <a-popover v-model="popVisible" title="选择月份" trigger="click">-->
      <!--        <a slot="content">-->
      <!--          <p><a-month-picker v-model="exportDate" style="width: 200px" format="YYYY/MM" placeholder="请选择月份" /></p>-->
      <!--          <p style="text-align: right"><a-button type="primary" @click="handleStaticsExport">确定</a-button></p>-->
      <!--        </a>-->
      <!--        <a-button type="primary">导出月度统计表</a-button>-->
      <!--      </a-popover>-->
      <!--      <a-popover v-model="yearVisible" title="选择年份" trigger="click">-->
      <!--        <a slot="content">-->
      <!--          <p>-->
      <!--            <a-date-picker-->
      <!--              :value="exportYear"-->
      <!--              mode="year"-->
      <!--              style="width: 200px"-->
      <!--              format="YYYY"-->
      <!--              :open="yearChoose"-->
      <!--              placeholder="请选择年份"-->
      <!--              @panelChange="chooseYear"-->
      <!--              @openChange="handleYearOpen"-->
      <!--            />-->
      <!--          </p>-->
      <!--          <p style="text-align: right"><a-button type="primary" @click="handleYearStaticsExport">确定</a-button></p>-->
      <!--        </a>-->
      <!--        <a-button type="primary">导出年度统计表</a-button>-->
      <!--      </a-popover>-->
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="serial_num"
      :columns="columns"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.charge_fees" :key="item.id">
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <div slot="payFeeItem" slot-scope="text, record">
        <div v-for="item in record.pay_fees" :key="item.id">
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getCommonOptions, getCustomer, downloadExcel } from '@/api/common'
import { filterOption } from '@/utils/util'
import { getCumsFinancePage, getCumsMetaOption } from '@/api/cums'
import debounce from 'lodash/debounce';
import moment from 'moment'
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {
        type: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
      defaultDate: [moment().startOf('month'), moment().endOf('month')],
      customerOps: [],
      customerInfos: [],
      customerInfo: undefined,
      fetching: false,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'serial_num',
          width: 100
        },
        {
          title: '服务类型',
          dataIndex: 'service_type',
          width: 150,
          customRender: (text) => {
            const types = {
              0: '代理报关',
              1: '代理运输',
              2: '代理订舱',
              3: '供应商仓储',
              4: '代理换单'
            }
            if (typeof text !== 'undefined') {
              const names = text.map(i => {
                return types[i]
              })
              return names.join('，')
            } else {
              return ''
            }
          }
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 150
        },
        {
          title: '客服员',
          dataIndex: 'customer',
          width: 120
        },
        {
          title: '应收费用',
          dataIndex: 'charge_fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '应收合计',
          dataIndex: 'charge_total',
          width: 200
        },
        {
          title: '应付费用',
          dataIndex: 'pay_fees',
          scopedSlots: { customRender: 'payFeeItem' }
        },
        {
          title: '应付合计',
          dataIndex: 'pay_total',
          width: 200
        },
        {
          title: '利润',
          dataIndex: 'profit_total',
          width: 200
        }
      ],
      confirmLoading: false,
      loadData: parameter => {
        return getCumsFinancePage('profit_list', Object.assign(parameter, this.queryParam)).then(res => {
          this.showTotal = '票数合计:' + res.total + ';应收合计:' + res.total_row.charge + ';应付合计:' + res.total_row.pay + ';利润合计:' + res.total_row.profit
          return res
        })
      },
      popVisible: false,
      yearVisible: false,
      exportDate: null,
      yearChoose: false,
      exportYear: null,
      dateVisible: false,
      exportDateTime: null,
      export_start_date: null,
      export_end_date: null,
      showTotal: '',
      bizTypeOps: [], // 业务类型
      feeOps: []
    }
  },
  created() {
    getCumsMetaOption('cums_meta_info', { type: 4 }).then(v => {
      this.bizTypeOps = v;
    });
    getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
      this.customerInfos = v;
    });
    getCommonOptions('fee', { department: 7, type: 1 }).then(v => {
      this.feeOps = v;
    });
    this.tableOnDateChange(this.defaultDate)
  },
  methods: {
    filterOption,
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    onInvoiceDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_start_date'] = startDate
        this.queryParam['invoice_end_date'] = endDate
      } else {
        this.queryParam['invoice_start_date'] = null
        this.queryParam['invoice_end_date'] = null
      }
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.queryParam['auth_agency'] = null;
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.queryParam['auth_agency'] = value.key;
      }
      this.customerOps = []
      this.fetching = false
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleExport(type, queryParam) {
      this.queryParam.ids = []
      if (this.selectedRowKeys.length > 0) {
        this.queryParam.ids = this.selectedRowKeys.join(',')
      }
      getCumsFinancePage(type, queryParam || this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    chooseYear(date) {
      this.exportYear = date
      this.yearChoose = false
    },
    handleYearOpen(status) {
      if (status) {
        this.yearChoose = true
      } else {
        this.yearChoose = false
      }
    },
    handleDateTimeStaticsExport() {
      if (this.export_start_date && this.export_end_date) {
        this.handleExport('export_datetime', {
          firstDateTime: this.export_start_date,
          endDateTime: this.export_end_date
        })
        this.dateVisible = false
      } else {
        this.$message.warning('请选择导出日期')
      }
    },
    handleStaticsExport() {
      if (this.exportDate) {
        const year = this.exportDate.year()
        const month = this.exportDate.month()
        this.handleExport('export_data', {
          year: year,
          month: month + 1
        })
        this.popVisible = false
      } else {
        this.$message.warning('请选择导出月份')
      }
    },
    handleYearStaticsExport() {
      if (this.exportYear) {
        const year = this.exportYear.year()
        this.handleExport('export_year', {
          year: year
        })
        this.yearVisible = false
      } else {
        this.$message.warning('请选择导出年份')
      }
    },
    onDateChange(date, dateString) {
      this.export_start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.export_end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
    },
    tableOnDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['record_start_time'] = startDate
        this.queryParam['record_end_time'] = endDate
      } else {
        this.queryParam['record_start_time'] = null
        this.queryParam['record_end_time'] = null
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    }
  }
}
</script>
